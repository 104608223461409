import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import dadosParteReducer from '../features/cadastroPeticionamento/components/dadosParte/DadosParteReducer';
import cadastroPeticionamentoReducer from '../features/cadastroPeticionamento/CadastroPeticionamentoReducer';
import confirmacaoPeticionamentoReducer from '../features/cadastroPeticionamento/components/confirmacaoPeticionamento/ConfirmacaoPeticionamentoReducer';
import uploadListPdfReducer from '../features/cadastroPeticionamento/components/uploadListPdf/UploadListPdfReducer';

export const rootReducer = combineReducers({
  router: routerReducer,
  dadosParteReducer,
  cadastroPeticionamentoReducer,
  confirmacaoPeticionamentoReducer,
  uploadListPdfReducer
});

export default rootReducer;
