import React, { useState, useEffect, Suspense } from 'react';
import Keycloak from 'keycloak-js';
import { Provider } from 'react-redux';
import { configureStore } from './../store/configureStore';
import { KEYCLOAK_CONFIG } from '../commons/constants';

const CadastroPeticionamento = React.lazy(() =>
  import('../features/cadastroPeticionamento')
);

const reduxStore = configureStore();

export const App = () => {
  const [keycloak, setKeycloak] = useState(null);
  const [isAuthenticated, setAuthenticated] = useState(false);

  // const keycloakRef = Keycloak('/keycloak.json');
  const keycloakRef = Keycloak(KEYCLOAK_CONFIG);

  useEffect(() => {
    keycloakRef.init({ onLoad: 'login-required' }).success(authenticated => {
      setKeycloak(keycloakRef);
      setAuthenticated(authenticated);
      localStorage.setItem('token', keycloakRef.idToken);

      setInterval(() => {
        keycloakRef
          .updateToken(70)
          .success(refreshed => {
            if (refreshed) {
              localStorage.setItem('token', keycloakRef.idToken);
            } else {
              console.warn(
                `Token não atualizado, válido por ${Math.round(
                  keycloakRef.tokenParsed.exp +
                    keycloakRef.timeSkew -
                    new Date().getTime() / 1000
                )} segundos`
              );
            }
          })
          .error(() => {
            console.error('Falha ao tentar atualizar token');
          });
      }, 60000);
    });
  }, []);

  if (isAuthenticated) {
    return (
      <Provider store={reduxStore}>
        <Suspense fallback={<div />}>
          <CadastroPeticionamento keycloak={keycloak} />
        </Suspense>
      </Provider>
    );
  }

  return <div>Inicializando...</div>;
};

export default App;
