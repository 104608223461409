import * as actions from './UploadListPdf.actions';
import initialState from './UploadListPdf.initialState';
import { createReducer } from '../../../../commons/redux/createReducer';

const setListaArquivos = (state, action) => ({
  ...state,
  listaArquivos: action.listaArquivos
});

const UploadListPdfReducer = createReducer(initialState, {
  [actions.setListaArquivosAction().type]: setListaArquivos
});

export default UploadListPdfReducer;
