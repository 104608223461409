import * as actions from './DadosParte.actions';
import initialState from './DadosParte.initialState';
import { createReducer } from '../../../../commons/redux/createReducer';

const setListaPartes = (state, action) => ({
  ...state,
  listaPartes: action.listaPartes
});

const DadosParteReducer = createReducer(initialState, {
  [actions.setListaPartesAction().type]: setListaPartes
});

export default DadosParteReducer;
