import * as actions from './ConfirmacaoPeticionamento.actions';
import initialState from './ConfirmacaoPeticionamento.initialState';
import { createReducer } from '../../../../commons/redux/createReducer';

const setNumeroProcesso = (state, action) => ({
  ...state,
  numeroProcesso: action.numeroProcesso
});

const ConfirmacaoPeticionamentoReducer = createReducer(initialState, {
  [actions.setNumeroProcessoAction().type]: setNumeroProcesso
});

export default ConfirmacaoPeticionamentoReducer;
