export const TIMEOUT = 30000;
export const PAGE_SIZE = 5000;
export const BASE_URL = window.MP_PETICIONAMENTO_EXTRAJUDICIAL_URL;
export const URL_UPLOAD_FILE = `${BASE_URL}/v1/file/upload`;
export const TAMANHO_MAXIMO_DESCRICAO_OBJETO = 4000;
export const KEYCLOAK_CONFIG = {
  realm: 'PETICIONAMENTO',
  clientId: 'mp-peticionamento-app',
  url: window.URL_KEYCLOAK
};
